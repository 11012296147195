.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.4s;
}

.modal-overlay.active {
  opacity: 1;
  visibility: visible;
}

.modal-container {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
  position: relative;
  max-width: 500px;
  width: 100%;
  transform: scale(0.9);
  transition: transform 0.4s ease-out;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.modal-container.active {
  transform: scale(1);
  opacity: 1;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-form input,
.modal-form textarea {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: border-color 0.3s;
}

.modal-form input:focus,
.modal-form textarea:focus {
  outline: none;
  border-color: #007bff;
}

.modal-form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-weight: bold;
}

.modal-form button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: none;
  font-size: 1.5em;
  cursor: pointer;
  transition: transform 0.2s;
}

.close-button:hover {
  transform: rotate(90deg);
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal-container {
    width: 90%;
  }
}
