.tabs-container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  max-width: 700px;
  margin: 40px auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.tabs-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  background: linear-gradient(45deg, #007bff, #0056b3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.tab {
  flex-grow: 1;
  padding: 15px 20px;
  cursor: pointer;
  color: white;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
  position: relative;
}

.tab:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s;
}

.tab:hover:before {
  opacity: 1;
}

.tab:hover {
  transform: translateY(-3px);
}

.active {
  background-color: white;
  color: #007bff;
  border-top: 3px solid #0056b3;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.tab-content {
  padding: 70px 100px;
  background-color: white;
  animation: fadeIn 0.5s;
  height: fit-content;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .tab {
    padding: 10px;
    font-size: 0.9em;
  }
  .tab-content {
    padding: 10px 30px;
  }
}
