.terms-container {
    padding: 20px;
    background-color: #f4f4f4;
    color: #333;
    font-family: Arial, sans-serif;
}

.terms-content {
    background-color: white;
    padding: 20px 250px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin-top: 20px;
}

.terms-container h1 {
    color: #2a2a2a;
    text-align: center;

}

.terms-content h2 {
    color: #4a76a8;
    text-align: center;
}

.terms-content p {
    font-size: 13px;
    line-height: 1.6;
    text-transform: uppercase;
    text-align: justify;
}

.terms-content ui li{
     font-size: 13px;
    line-height: 1.6;
    text-transform: uppercase;
    text-align: justify;
}
