.footer-area {
  background-color: black;
  color: white;
  padding-top: 50px;
}

.footer-widget h3 {
  color: #007bff;
  margin-bottom: 25px;
  font-weight: 600;
}

.footer-widget ul {
  list-style: none;
  padding: 0;
  text-decoration: none;
}

.footer-widget ul li {
  margin-bottom: 10px;
}

.footer-widget ul li a {
  color: white;
  transition: color 0.3s ease;
  text-decoration: none;
}

.footer-widget ul li a:hover {
  color: #007bff;
  text-decoration: none;
}

.footer-icon a {
  display: inline-block;
  margin-right: 10px;
  color: white;
  transition: color 0.3s ease;
}

.footer-icon a:hover {
  color: #007bff;
}

.copy-right-support,
.copy-right-svg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.widget__subscribe input[type="email"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.widget__subscribe button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.widget__subscribe button:hover {
  background-color: #0056b3;
}

.border-line,
.border-line-2 {
  border-top: 1px solid #eaeaea;
  padding-top: 15px;
  margin-top: 15px;
}

#foot {
  background-color: black;
}

.rows {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
}

#foot1 {
  width: 30vw;
  padding-left: 5vw;
  margin-bottom: 3vw;
}

@media screen and (max-width: 900px) {
  .rows {
    grid-template-columns: repeat(2, 1fr);
  }

  #foot1 {
    width: fit-content;
  }
}

@media screen and (max-width: 700px) {
  .rows {
    grid-template-columns: repeat(1, 1fr);
  }

  #foot1 {
    width: fit-content;
  }
}

.footer-img {
  margin-bottom: 1vh;
}

#img1 {
  margin-bottom: 5vh;
}

.a a {
  margin-left: 10px;
}
