.cor-img {
  width: 100%;
  height: 80vh;
  background-size: cover;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  background-color: #f4f4f4;
}

.open {
  width: 100%;
  height: 100%;
}

#open {
  padding-top: 70px;
}

.service-card {
  width: 300px;
  margin: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.service-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.service-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.service-card h3 {
  color: #333;
  font-size: 1.2em;
  margin: 10px;
  text-align: center;
}

.service-card p {
  color: #666;
  font-size: 0.9em;
  margin: 0 10px 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .open {
    width: 100%;
    margin: 0;
  }

  .service-card {
    width: 80%;
    margin: 15px 0;
  }
  .cor-img {
    height: auto;
  }
}
@media (max-width: 900px) {
  .cor-img {
    height: auto;
  }
}

.heading {
  text-align: center;
  font-size: 3em;
  color: #0056b3;
  margin: 40px 0;
  position: relative;
  overflow: hidden;
  font-weight: bold;
}

.heading::before,
.heading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 3px;
  background: linear-gradient(90deg, transparent, #29f1c3, transparent);
  transform: translate(-50%, -50%);
}

.heading::before {
  filter: blur(5px);
}

.heading::after {
  filter: blur(15px);
}

@keyframes glow {
  0%,
  100% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
}

.heading:hover::before,
.heading:hover::after {
  animation: glow 1.5s infinite alternate;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.heading {
  animation: slideIn 1s ease-out;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;
}

.service-card {
  width: 300px;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: #fff;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.service-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.service-card:hover .service-image {
  opacity: 0.9;
}

.service-card h3,
.service-card p {
  padding: 0 15px;
  text-align: center;
}

.service-card h3 {
  margin-top: 10px;
  font-size: 1.4em;
  color: #333;
  transition: color 0.3s ease;
}

.service-card:hover h3 {
  color: #007bff;
}

.service-card p {
  color: #666;
  font-size: 1em;
  margin: 10px 0 20px;
}

@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .service-card {
    width: 80%;
  }
}

.about-us-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 50px 20px;
  background-color: #f8f9fa;
}

.about-us-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.about-us-image img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.about-us-content {
  max-width: 600px;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUpFadeIn 1s ease forwards;
}

.about-us-content h2 {
  color: #0056b3;
  margin-bottom: 20px;
  font-size: 2em;
}

.about-us-content p,
.about-us-content ul {
  color: #333;
  line-height: 1.6;
  margin-bottom: 15px;
}

@keyframes slideUpFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .about-us-container {
    flex-direction: column;
    text-align: center;
  }

  .about-us-image,
  .about-us-content {
    max-width: 100%;
  }
}

.info-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 60px 20px;
  background-color: #f8f9fa;
}

.info-block {
  position: relative;
  width: 80%;
  max-width: 800px;
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.info-block:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  transform: translateY(-10px);
}

.info-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.info-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.info-block:hover .info-image img {
  transform: scale(1.05);
}

.info-text {
  padding: 20px;
}

.info-text h3 {
  font-size: 1.8em;
  color: #0056b3;
  margin-bottom: 15px;
}

.info-text p {
  color: #666;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .info-block {
    width: 90%;
  }

  .info-image {
    height: 150px;
  }
}

.know-us-better-container {
  text-align: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

.know-us-title {
  font-size: 2.2em;
  color: #004d99;
  margin-bottom: 30px;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInDown 0.6s ease-out forwards;
}

.know-us-content p {
  color: #333;
  font-size: 1.1em;
  line-height: 1.8;
  margin-bottom: 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;
  animation-delay: 0.3s;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.know-us-better-container:hover {
  background-color: #e6f0ff;
}

@media (max-width: 768px) {
  .know-us-title {
    font-size: 1.8em;
  }
}
