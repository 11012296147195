.stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  background-color: #f5f5f5;
}

.stat {
  background: #007bff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.stat:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.number {
  font-size: 2.5em;
  font-weight: bold;
  color: #f5f5f5;
  margin: 0;
  transition: color 0.3s;
}

.number:hover {
  color: black; /* Change to your theme color */
}

.label {
  font-size: 1em;
  color: #f5f5f5;
  margin-top: 5px;
}

/* Keyframes for count-up effect - placeholder, JavaScript needed for dynamic effect */
@keyframes countUp {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Apply the countUp animation */
.number {
  animation: countUp 1s ease forwards;
}

@media (max-width: 768px) {
  .stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 440px) {
  .stats {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
