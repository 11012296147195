/* Base Styles */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5%;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  color: white;
  position: fixed;
  width: 100%;
}

.header .logo img {
  height: 50px;
  transition: transform 0.3s ease;
  margin-bottom: 20px;
}

.header .logo img:hover {
  transform: scale(1.1);
}

/* Navigation Styles */
.navigation ul {
  list-style: none;
  display: flex;
}

.navigation ul li {
  margin-left: 30px;
  position: relative;
  padding-top: 10px;
}

.navigation ul li a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding: 5px 0;
  position: relative;
  display: inline-block;
}

.navigation ul li a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.navigation ul li a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

/* Hamburger Menu Styles */
.nav-toggle {
  display: none;
  cursor: pointer;
  border-radius: 20%;
  background-color: white;
}

.nav-toggle .bar {
  display: block;
  width: 15px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: black;
}

/* Show nav-toggle on smaller screens */
@media (max-width: 768px) {
  .nav-toggle {
    display: block;
    transition: 3s ease-in;
  }

  .navigation {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background: #6e8efb;
    flex-direction: column;
    align-items: center;
    display: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  .navigation.visible {
    display: flex;
    background-color: black;
  }

  .navigation ul {
    flex-direction: column;
  }

  .navigation ul li {
    margin: 5px 0;
  }

  .navigation ul li a {
    font-size: 18px;
  }
}

/* Rotate hamburger bars on toggle */
.nav-toggle.active .bar:nth-child(2) {
  opacity: 0;
}

.nav-toggle.active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.nav-toggle.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}
