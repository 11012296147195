.inf-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
}

.inf-section:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.inf-image {
  width: 100%;
  max-width: 700px;
  border-radius: 8px;
  transition: transform 0.3s ease;
  height: auto;
  margin-top: 50px;
}

.inf-image:hover {
  transform: scale(1.05);
}

.inf-content {
  max-width: 600px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
}

.inf-heading {
  color: #007bff;
  font-size: 2em;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
}

.inf-heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 50px;
  background: #007bff;
  transition: width 0.3s ease;
}

.inf-heading:hover::after {
  width: 100%;
}

.inf-points {
  list-style: inside;
  padding: 0;
  font-size: 1.2em;
  color: #333;
}

.inf-points li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  list-style: none;
  margin-left: 15px;
}

.inf-points li::before {
  content: "▹";
  position: absolute;
  left: 0;
  color: #007bff;
  transition: transform 0.2s ease;
}

.inf-points li:hover::before {
  transform: translateX(5px);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .inf-section {
    flex-direction: column;
    text-align: center;
  }

  .inf-image {
    max-width: 100%;
  }
}

/* Advanced CSS with Animations */

/* Base styles */

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

/* Header styles */
.header {
  text-align: center;
  animation: fadeInUp 1s forwards;
}

.header h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.header p {
  font-size: 1.1em;
  line-height: 1.6;
}

/* WelcomeBanner.css */
.welcome-container {
  font-family: "Arial", sans-serif;
  color: #333;
  background-color: #f4f4f4;
  overflow: hidden;
}

.banne {
  background: linear-gradient(120deg, #0056b3, #0096c7);
  color: white;
  padding: 40px 20px;
  text-align: center;
  animation: slideInFromLeft 1s ease-out forwards;
}

.short-intro {
  background: white;
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transform: scale(0.9);
  opacity: 0;
  animation: popIn 0.5s ease-out 1s forwards;
}

/* Animations */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsive */
@media (max-width: 768px) {
  .banne,
  .short-intro {
    padding: 20px;
  }
}

/* MissionVisionValues.css */
.mvv-container {
  font-family: "Arial", sans-serif;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  color: #333;
}

.mvv-container section {
  background: #fff;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.mvv-container section:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.mvv-container h2 {
  color: #0056b3;
  margin-bottom: 10px;
}

.mvv-container p,
.mvv-container ul {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive */
@media (max-width: 768px) {
  .mvv-container {
    padding: 10px;
  }
}
/* PartnerWithUs.css */
.partner-container {
  font-family: "Arial", sans-serif;
  color: #333;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.partner-container > section {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.5s ease-out forwards;
}

.why-partner {
  --color: #17a2b8;
}

.services-offered {
  --color: #28a745;
}

.partner-container > section h2 {
  color: var(--color);
  margin-bottom: 15px;
}

/* Animations */
@keyframes fadeInUp {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.why-partner {
  animation-delay: 0.3s;
}

.services-offered {
  animation-delay: 0.6s;
}

/* Responsive */
@media (max-width: 768px) {
  .partner-container {
    padding: 10px;
  }
  .partner-container > section {
    padding: 15px;
  }
}
/* ContactAndBlog.css */
.contact-blog-container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  max-width: 1200px;
  margin: 40px auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  padding: 20px;
}

.contact-blog-container > section {
  padding: 25px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transform: translatey(0px);
  opacity: 0;
  animation: enter 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  position: relative;
  overflow: hidden;
}

.contact:before,
.blog-news:before {
  content: "";
  position: absolute;
  top: -50%;
  right: -50%;
  width: 200%;
  height: 200%;
  background-color: var(--color);
  transition: all 0.7s;
  border-radius: 35%;
  z-index: 0;
  opacity: 0.6;
}

.contact:hover:before,
.blog-news:hover:before {
  width: 220%;
  height: 220%;
  top: -60%;
  right: -60%;
}

.contact {
  --color: #007bff;
}

.blog-news {
  --color: #20c997;
}

.contact-blog-container > section h2 {
  color: var(--color);
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.contact-details,
.blog-news p {
  margin-bottom: 8px;
  position: relative;
  z-index: 1;
}

.contact-details {
  font-size: 1.1em;
}

/* Animations */
@keyframes enter {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive */
@media (max-width: 768px) {
  .contact-blog-container {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .contact-blog-container > section {
    padding: 20px;
  }
}

.ul li {
  list-style: none;
}
